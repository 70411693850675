<template>
  <div class="course-search-box" v-wechat-title="'课程搜索'">
    <div class="course-list-search">
      <van-search
        v-model="searchValue"
        shape="round"
        show-action
        placeholder="搜索关键词"
        :left-icon="require('@/assets/img/ss.png')"
        @search="onSearch"
        @cancel="onCancel"
      />
    </div>
    <div class="course-search-history" v-if="!isShow">
      <p>
        搜索历史
        <span @click="delSearchHistory"><van-icon name="delete-o" /></span>
      </p>
      <div v-if="searchHistory != ''">
        <span v-for="el in searchHistory" :key="el.title" @click="searchCourse(el.title)">{{ el.title }}</span>
      </div>
    </div>
    <div v-if="isShow">
      <template v-if="courseList != ''">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="course-list-box">
            <div v-for="(item, index) in courseList" :key="index" @click="courseInfo(item.id)">
              <p>
                <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')" />
              </p>
              <div>
                <span>{{item.courseName}}</span>
                <p>
                  <span>共{{item.chapterTotal}}节课</span>
                  <span>{{item.viewed?item.viewed:0}}人浏览</span>
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </template>
      <template v-else>
        <van-empty
          style="margin-top: 80px"
          class="custom-image"
          :image="require(`@/assets/img/4886@2x.png`)"
          description="暂无课程内容"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { getCourseList } from "@/api/activity";
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      searchValue: "",
      loading: false,
      finished: false,
      page: {
        current: 1,
        size: 9,
        pageSize: 1,
      },
      courseList: [],
      isShow: false,
      searchHistory: [], //搜索历史
    };
  },
  mounted() {
    if (localStorage.getItem("searchHistory")) {
      this.searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    }
  },
  methods: {
    delSearchHistory() {
      this.$dialog
        .confirm({
          title: "标题",
          message: "确定删除搜索历史记录？",
        })
        .then(() => {
          this.searchHistory = [];
          localStorage.removeItem("searchHistory");
          this.$toast("删除成功");
        })
        .catch(() => {});
    },
    onSearch() {
      let { searchHistory, searchValue } = this;
      if (searchValue == "") {
        this.$toast("请输入搜索关键词");
        return;
      }
      let isAdd = true;
      if (searchHistory != "") {
        searchHistory.forEach((element) => {
          if (element.title == searchValue) {
            isAdd = false;
          }
        });
      }
      if (isAdd) {
        let obj = {
          title: searchValue,
        };
        this.searchHistory.unshift(obj);
      }
      this.searchHistory.splice(3);
      let data = JSON.stringify(this.searchHistory);
      localStorage.setItem("searchHistory", data);
      this.isShow = true;
      this.loadGetCourseList()
    },
    searchCourse(val){
      this.isShow = true;
      this.searchValue = val
      this.loadGetCourseList()
    },
    onCancel() {
      this.$router.go(-1)
    },
    // 列表
    loadGetCourseList() {
      let { page, searchValue } = this;
      let data = {
        courseName: searchValue,
        orderField: "",
        ascOrDesc: "desc",
        courseTypeId: "",
      };
      getCourseList(page, data).then((res) => {
        this.loading = false;
        this.courseList = [...this.courseList, ...res.records];
        this.page.pageSize = res.pages;
        this.finished = false;
      });
    },
    onLoad() {
      if (this.page.pageSize == this.page.current) {
        this.finished = true;
        return;
      }
      this.page.current++;
      this.loading = true;
      this.loadGetCourseList();
    },
    courseInfo(id){
      this.$router.push({
        path: "/course/info",
        query: { id },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.course-search-box {
  height: calc(100vh - 56px);
  background: #f6f6f6;
  .course-list-search {
    padding: 8px 16px 0;
  }
  .course-search-history {
    margin-top: 24px;
    padding: 0 16px;
    > p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      margin-bottom: 16px;
      > span {
        font-size: 16px;
      }
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      > span {
        height: 28px;
        background: rgba(186, 59, 59, 0.05);
        border-radius: 14px;
        padding: 0 14px;
        line-height: 28px;
        font-size: 12px;
        color: #333333;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .course-list-box {
    padding: 0 16px;
    > div {
      margin-top: 10px;
      background: #fff;
      padding: 12px;
      border-radius: 4px;
      display: flex;
      > p {
        width: 140px;
        height: 78px;
        margin-right: 12px;
        position: relative;
        margin-bottom: 0;
        > img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 4px;
        }
      }
      > div {
        flex: 1;
        > span {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          word-break: break-all;
          height: 40px;
          overflow: hidden;
        }
        > p {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          margin-top: 21px;
          > span {
            font-size: 12px;
            line-height: 17px;
            color: #999999;
          }
        }
      }
    }
  }
}
.no-search-history {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #666;
}
</style>
<style>
.course-list-search .van-search {
  background: none;
  padding: 0;
}
.course-list-search .van-search__content {
  background: #fff;
  color: #c4c4c4;
}
.custom-image .van-empty__image {
  width: 94px;
  height: 82px;
}
.course-list-search .van-field__left-icon{
  display: flex;
  align-items: center;
}
</style>